import React from 'react';

const NoDataBox: React.FC = () => (
  <div id="noDataBox" className="status-message status-info border border-info margin-bottom"
       style={{borderRadius: '0.25em'}}>
    <h2>Keine aktiven Leistungen</h2>
    <p>
      Wir konnten keine aktiven Leistungen für ihren Account finden.
    </p>
  </div>
);

export {NoDataBox};
