const getAppContext = (): string => {
  const domainsParts = window.location.host.split('.');
  return domainsParts.slice(domainsParts.length - 2).join('.');
};

const getDomain = (): string => {
  const applicationContext = getAppContext();

  if (window.location.hostname.startsWith('local')) {
    return 'sandbox-immobilienscout24.de';
  }

  return applicationContext || 'immobilienscout24.de';
};

const getHost = (): string => {
  return window.location.host;
};

const getIs24LegacyHost = (): string => {
  return `www.${getDomain()}`;
};

const getEnvironment = (): string => {
  const host = window.location.host;
  if(host.includes('sandbox')) {
    return 'box'
  }
  return 'pro';
};

export {getDomain, getIs24LegacyHost, getHost, getEnvironment};
