import React from 'react';
import {getDomain} from '../resolveHost';
import makeAsyncScriptLoader from 'react-async-script';

const counterName = '/scoutmanager/verwaltung/leistungsuebersicht/index';
const tealiumUrl = `https://tracking.${getDomain()}/tr.js?cP--svc_module_name=defaultModuleName&cP--countername=${counterName}&addOnClick`;

const AsyncTealiumLoader: React.FC = () => null;

export default makeAsyncScriptLoader(tealiumUrl)(AsyncTealiumLoader);
