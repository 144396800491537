import React from "react";

export const ImmopointsOverviewLinkBox: React.FC = () => {

  const getImmopointsOverviewLinkURL = () => {
    const hostname = window.location.hostname;
    if (hostname.includes('sandbox')) {
      return `https://www.sandbox-immobilienscout24.de/scoutmanager/immopunkte/uebersicht/?return_url=${window.location.href}`
    } else {
      return `https://www.immobilienscout24.de/scoutmanager/immopunkte/uebersicht/?return_url=${window.location.href}`
    }

  }
  return (
    <div className="border border-info background-info margin-bottom" style={{borderRadius: '0.25em'}} role="listitem">
      <h4 className="margin-s">ImmoPunkte</h4>
      <div className="margin-s">
        <a href={getImmopointsOverviewLinkURL()}>zur Übersicht</a>
      </div>
    </div>
  )
};
