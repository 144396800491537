import React from "react";

const ErrorBox: React.FC = () => (
  <div
    id="errorBox"
    className="status-message status-error margin-bottom"
    style={{ borderRadius: "0.25em" }}
    role="alert"
  >
    <h4>Achtung!</h4>
    <p>
      Leider konnten wir nicht alle Daten ihrer Leistungsübersicht laden. Bitte
      versuchen sie es später noch einmal.
    </p>
  </div>
);

export { ErrorBox };
