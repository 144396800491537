import React from "react";
import { ContingentBox } from "../ContingentBox";

interface ProductBoxProps {
  title: string;
  totalCredits: string;
  consumedCredits: string;
  availableCredits: string;
  extentContingentLink: string;
}

const ProductBox: React.FC<ProductBoxProps> = ({
  title,
  totalCredits,
  consumedCredits,
  availableCredits,
  extentContingentLink
}) => (
  <div
    className="border border-info margin-bottom"
    style={{ borderRadius: "0.25em" }}
    role="listitem"
  >
    <h3 className="margin-s">{title}</h3>
    <div className="border-info border-bottom" />
    <div className="margin-s">
      <p className="float-left">Aktuelles Kontingent:</p>
      <p className="float-right">{totalCredits}</p>
      <div style={{ clear: "both" }} />
      <p className="float-left inline-block">
        Im Rahmen des Kontingents veröffentlicht:
      </p>
      <p className="float-right inline-block">{consumedCredits}</p>
      <div style={{ clear: "both" }} />
    </div>
    <div className="border-info border-bottom" />
    <div className="margin-s">
      <p className="font-bold float-left">Verfügbares Kontingent:</p>
      <p className="font-bold float-right">{availableCredits}</p>
      <div style={{ clear: "both" }} />
      {extentContingentLink.length > 0 && (
        <div>
          <a href={extentContingentLink} className="float-left">
            Kontingent erweitern
          </a>
          <div style={{ clear: "both" }} />
        </div>
      )}
    </div>
  </div>
);

const mapToProductBox = (productBoxes: ContingentBox[]) => {
  return productBoxes.map((item, index) => (
    <ProductBox
      key={index}
      title={item.headline || ""}
      totalCredits={item.entries[0].totalCredits + ""}
      consumedCredits={item.entries[0].consumedCredits + ""}
      availableCredits={item.entries[0].availableCredits + ""}
      extentContingentLink={item.detailsLink || ""}
    />
  ));
};

export { ProductBox, mapToProductBox };
