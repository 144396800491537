import React from 'react';
import {ContingentBox} from "../ContingentBox";

interface Term {
  description: string
  availableCredits: string
  totalCredits: string
}

interface Props {
  title: string,
  terms: Term[]
  detailsLink: string
}

const OntopProductBox: React.FC<Props> = ({title, terms, detailsLink}) => (
  <div className="border border-info background-info margin-bottom" style={{borderRadius: '0.25em'}} role="listitem">
    <h4 className="margin-s">{title}</h4>

    <div>
      {terms.map((term, index) => (
        <div className="margin-s" key={index}>
          <p>{term.description}</p>
          {term.availableCredits === 'unbegrenzt' ? (
            <p>Noch verfügbar:{'\u00A0'}<span className="font-bold font-brandorange">{term.availableCredits}</span></p>
          ) : (
            <p>Noch verfügbar:{'\u00A0'}<span className="font-bold font-brandorange">{term.availableCredits}{'\u00A0'}
              von{'\u00A0'}{term.totalCredits}</span></p>
          )}
        </div>
      ))}
    </div>

    {detailsLink.length > 0 && <div className="margin-s"><a href={detailsLink}>Details</a></div>}
  </div>
);

const mapToOtpBox = (otpBoxes: ContingentBox[]) => {
  return otpBoxes.map((item, index) => (
      <OntopProductBox
        key={index}
        title={item.headline || ''}
        terms={
          item.entries.map(entry => ({
            description: entry.description || '',
            totalCredits: (entry.totalCredits) + '',
            availableCredits: (entry.availableCredits) + '',
          }))
        }
        detailsLink={item.detailsLink || ''}
      />
    )
  );
};

export {OntopProductBox, mapToOtpBox};
