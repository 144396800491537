import Header from "./Header";
import OverviewSpace from "./OverviewSpace";
import {remoteUrls} from "./dataLoader";
import React from "react";

const App: React.FC<{ accessToken: string }> = ({accessToken}) => (
    <div className="viewport">
        <div className="page-wrapper border-none">
            <Header/>
            <div className="content-wrapper padding-top">
                <OverviewSpace accessToken={accessToken} remoteUrls={remoteUrls}/>
            </div>
        </div>
    </div>
);

export default App;
