import 'js-errorlogging';
import {getDomain} from './resolveHost';

const initJsErrorLogging = () => {
  const domain = getDomain();
  const stage = domain.startsWith('sandbox') ? 'test' : 'prod';

  window.IS24 = window.IS24 || {};
  window.is24PerformanceTrackingDisabled = true;
  window.IS24.serviceName = `book-overview-${stage}`;
  window.IS24.applicationContext = `https://leistungen.${domain}`;
};

export {initJsErrorLogging};

declare global {
  interface Window {
    is24PerformanceTrackingDisabled: boolean
    IS24: {
      serviceName: string
      applicationContext: string
    }
  }
}
