import React from 'react';
import {getIs24LegacyHost} from './resolveHost';

declare global {
  interface Window { activeHeaderItemSelector: string; }
}

class Header extends React.Component {
  componentDidMount() {
    window.activeHeaderItemSelector = "#is24-sm-mgmt";
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://${getIs24LegacyHost()}/scoutmanager-header/main.js`;
    document.head.appendChild(script);
  }
  render() {
    return (
      <div className="margin-bottom">
        <div id="scoutmanager-header" absolute-urls="true" data-new-scoutmanager></div>
      </div>
    )
  }
}

export default Header;
