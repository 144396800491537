import cuid from 'cuid';
import {getDomain} from '../resolveHost';

const getTokenFromUrl = (): string | null => {
  const query = new URLSearchParams(window.location.hash.substr(1));
  return query.get('access_token');
};

const removeHash = (): void => {
  if (typeof window.history.replaceState !== 'undefined') {
    window.history.replaceState('', document.title, window.location.pathname + window.location.search);
  }
};

const authorizeMe = (): void => {
  const query = new URLSearchParams({
    state: cuid(),
    response_type: 'token',
    client_id: 'book-overview',
    redirect_uri: `https://${window.location.host}`
  });

  window.location.href = `https://publicauth.${getDomain()}/oauth/authorize?${query.toString()}`;
};

export {authorizeMe, removeHash, getTokenFromUrl};


