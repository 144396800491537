import React from "react";
import { mapToProductBox } from "./boxes/ProductBox";
import { mapToOtpBox } from "./boxes/OntopProductBox";
import AsyncTealiumLoader from "./tracking/AsyncTealiumLoader";
import { ErrorBox } from "./boxes/ErrorBox";
import { loadAllBoxes } from "./dataLoader";
import { LoaderAnimation } from "./util/LoderAnimation";
import { NoDataBox } from "./boxes/NoDataBox";
import { ContingentBox } from "./ContingentBox";
import { ImmopointsOverviewLinkBox } from "./boxes/ImmopointsOverviewLinkBox";

interface Props {
  accessToken: string;
  remoteUrls: string[];
}

interface State {
  productBoxes: ContingentBox[];
  otpBoxes: ContingentBox[];
  loading: boolean;
  hasError: boolean;
}

class OverviewSpace extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      productBoxes: [],
      otpBoxes: [],
      loading: true,
      hasError: false
    };
  }

  async componentDidMount() {
    const { boxes, hasErrors } = await loadAllBoxes(
      this.props.accessToken,
      this.props.remoteUrls
    );
    this.setState({
      productBoxes: boxes.filter(box => box.boxType === "white"),
      otpBoxes: boxes.filter(box => box.boxType === "blue"),
      loading: false,
      hasError: hasErrors
    });
  }

  render() {
    const { productBoxes, otpBoxes, hasError, loading } = this.state;

    const whiteBoxes = mapToProductBox(productBoxes);
    const blueBoxes = mapToOtpBox(otpBoxes);

    const hasNoData =
      !hasError && !loading && whiteBoxes.length <= 0 && blueBoxes.length <= 0;

    return (
      <main>
        <h2>Leistungsübersicht</h2>
        {hasError && <ErrorBox />}
        {loading && <LoaderAnimation />}
        {hasNoData && <NoDataBox />}
        <div className="grid grid-flex gutter-s" role="list">
          <div className="grid-item palm-one-whole lap-one-whole desk-four-tenths">
            {whiteBoxes}
          </div>
          <div className="grid-item palm-one-whole lap-one-whole desk-six-tenths">
            {blueBoxes}
            <ImmopointsOverviewLinkBox />
          </div>
        </div>
        <AsyncTealiumLoader />
      </main>
    );
  }
}

export default OverviewSpace;
