import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import {authorizeMe, getTokenFromUrl, removeHash} from './oauth/oauth2';
import {initJsErrorLogging} from './jsErrorLogging';
import App from "./App";

(() => {
  initJsErrorLogging();

  const accessToken = getTokenFromUrl();
  if (accessToken === null) {
    authorizeMe();
    return;
  } else {
    removeHash();
  }

  ReactDOM.render(
    <App accessToken={accessToken} />,
    document.getElementById('app-root')
  );
})();
